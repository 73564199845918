export const role = 'menu-item';

export default (dc, {defaultModel, typeSidebarItems, selectorItem, ...config}) => {
    const type = config.typeSidebarItem;

    const prevSelectorItem = 'data-prev';

    const traits = [
        {
            full: 1,
            type: 'button',
            label: false,
            text: 'Style Active',
            command: (ed) => {
                const openSm = ed.Panels.getButton('views', 'open-sm');
                openSm && openSm.set('active', 1);
                const cls = `.${config.classSidebarItem}.${config.classSidebarItemActive}`;
                ed.StyleManager.setTarget(cls, { targetIsClass: 1 })
            },
        },
    ];
    const getPrevCntId = cmp => cmp.getAttributes()[prevSelectorItem];

    dc.addType(type, {
        model: {
            defaults: {
                name: 'Sidebar Item',
                copyable: false,
                removable: false,
                highlightable: false,
                attributes: { role },
                classes: config.classSidebarItem,
                traits,
                ...config.sidebarItemProps
            },
            init() {
                this.on('removed', this.__onRemove);
            },
            __initItem() {
                if (this.itemContent) return;

                let content = this.getItemContent();
                console.log('CONTENT', content)

                // In case the content was found via previous id (cloned from parent)
                if (content && getPrevCntId(content)) {
                    const id = content.getId();
                    const itemId = this.getId();
                    content.addAttributes({ id, 'aria-labelledby': itemId, hidden: true });
                    content.removeAttributes(prevSelectorItem);
                    this.addAttributes({ [selectorItem]: id, id: itemId });
                }

                // If the item content was found I'll attach it to the item model
                // otherwise I'll create e new one
                if (!content) {
                    console.log('if !content')
                    const items = this.getItemsType();
                    const cnts = items.getContentsType();
                    content = cnts.append({
                        type: config.typeItemContent,
                        components: config.templateItemContent(this),
                    })[0];
                    console.log('if !content', content)
                    const id = content.getId();
                    const itemId = this.getId();
                    content.addAttributes({ id, 'aria-labelledby': itemId, hidden: true });
                    this.addAttributes({ [selectorItem]: id, id: itemId });
                    this.itemContent = content;
                }

                this.itemContent = content;
            },
            __onRemove() {
                const content = this.getItemContent();
                content && content.remove();
                this.getItemsType().trigger('rerender')
            },

            getItemsType() {
                return this.closestType(typeSidebarItems);
            },

            getItemContent() {
                const id = this.getAttributes()[selectorItem];
                const items = this.getItemsType();

                if (!items || !id) return;

                const contents = items.findContents();

                return contents.filter(c =>
                    c.getId() === id || getPrevCntId(c) === id
                )[0];
            },

            clone(opts = {}) {
                const fromParent = opts._inner;
                if (fromParent) {
                    const itemCont = this.getItemContent();
                    itemCont && itemCont.addAttributes({ [prevSelectorItem]: itemCont.getId() });
                }
                const cloned = defaultModel.prototype.clone.apply(this, arguments);
                !fromParent && cloned.addAttributes({ [selectorItem]: '' });
                return cloned;
            }
        },
    });
}