import handWithMedalIcon from './components-icons/totalInfo-icons/hand-with-medal.svg';

const totalInfoComponents = () => {
    const itemStyles = `
        display: flex; 
        flex-direction: column; 
        flex-grow: 1; 
        flex-basis: 50%;
        margin-top: 26px
    `
    const itemTitleStyles = `
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #C6DCFC;
    `
    const itemTextStyles = `
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
    `

    return `
        <div style="display: flex; flex-direction: column">
          <div style="display: flex; flex-wrap: wrap">
            <div style="${itemStyles}; border-right: 1px solid #579BFF">
              <span style="${itemTitleStyles}">Fuel Usage</span>
              <span style="${itemTextStyles}">2903.89 Ltr</span>
            </div>
            <div style="${itemStyles}; padding-left: 30px;">
              <span style="${itemTitleStyles}">KM Driven</span>
              <span style="${itemTextStyles}">2903.89 Ltr</span>
            </div>
            <div style="${itemStyles}; border-right: 1px solid #579BFF">
              <span style="${itemTitleStyles}">Total Cost</span>
              <span style="${itemTextStyles}">$3,00,290.00</span>
            </div>
            <div style="${itemStyles}; padding-left: 30px;">
              <span style="${itemTitleStyles}">Top Speed</span>
              <span style="${itemTextStyles}">$5.2K</span>
            </div>
          </div>
         
        </div>
        <div style="object-fit: cover; overflow: hidden">
          <img src="${handWithMedalIcon}" alt="">
        </div>
    `
}

export const totalInfoModel = {
    model: {
        defaults: {
            attributes: { id: 'total-info' },
            style: {
                display: 'flex',
                'flex-direction': 'column',
                'justify-content': 'center',
                'align-items': 'center',
                padding: '0 23px',
                width: '360px',
                height: '530px',
                background: '#5D53C2',
                'box-shadow': '0px 20px 101px rgba(148, 148, 148, 0.25)',
                'border-radius': '14px',
            },
            components: totalInfoComponents()
        }
    }
}