export const role = 'sidebar-item-icon';

export default (dc, config) => {
    const type = config.typeSidebarItemIcon;

    dc.addType(type, {
        model: {
            defaults: {
                // script,
                // classactive: config.classSidebarItemActive,
                // selectoritem: config.selectorItem,
                // 'script-props': ['classactive', 'selectoritem'],
                name: 'Sidebar Item',
                copyable: false,
                removable: false,
                highlightable: false,
                attributes: { role },
                // components: config.templateIcon,
                classes: config.classSidebarIconBlock,
                ...config.sidebarItemIconProps
            },
        },
    });
}