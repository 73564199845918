export default (dc, config) => {

    dc.addType(config.typeItemContents, {
        model: {
            defaults: {
                name: 'Item Contents',
                draggable: false,
                droppable: false,
                copyable: false,
                removable: false,
                classes: config.classItemContents,
                ...config.itemContentsProps
            }
        },
    });
}