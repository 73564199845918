import hatIcon from './components-icons/header-icons/hat.svg';
import energyIcon from './components-icons/header-icons/energy-icon.svg';
import logoIcon from './components-icons/header-icons/logo.svg';
import bellIcon from './components-icons/header-icons/bell-icon.svg';
import avatarIcon from './components-icons/header-icons/avatar.svg';

const headerComponents = (fullName) => {
    const userName = fullName.split(' ')[0];

    return `
          <div id="header-wrapper" class="cell" style="display: flex; width: 100%; height: 87px">
            <div id="logo" style="min-width: 280px; padding-top: 17px; height: 100%; display: flex; justify-content: center; align-items: center; background: #FFFFFF">
              <img style="height: 31px" src="${logoIcon}" alt="logo">
            </div>
            <div id="user-info-wrapper" style="display: flex; align-items: center; justify-content: space-between; padding: 30px 33px 15px 46px; width: 100%; height: 100%; background: #FAFAFE">
                <div
                  id="userInfo"
                  style="display: flex; justify-content: space-between;"
                >
                  <div style="width: 46px; height: 46px; object-fit: cover; overflow: hidden">
                    <img src="${avatarIcon}" alt="">
                  </div>
                  <div style="padding: 11px 9px 9px; display: flex; flex-direction: column">
                    <sapn style="color: #5F6165;
                                 font-weight: 600;
                                 font-size: 14px;
                                 line-height: 10px;"
                    >
                    ${fullName}
                    </sapn>
                    <div style="display: flex; align-items: center; padding-top: 9px">
                      <div style="width: 7px; height: 7px; border-radius: 50%; background-color: #534ABA"></div>
                      <span style="font-weight: 600; font-size: 13px; line-height: 10px; color: #534ABA; padding-left: 5px">Online</span>
                    </div>
                    
                  </div>
                  <div style="padding: 9px 0">
                    <span style="background: #F6876A; color: #FFFFFF; box-shadow: 0 0 12px rgba(111, 110, 255, 0.5); border-radius: 4px; font-weight: 700;font-size: 11px; line-height: 10px;">
                      PRO
                    </span>
                  </div>
                </div>
                
                <div id="welcomeUser" style="font-weight: 500; font-size: 24px; line-height: 36px; text-transform: capitalize; color: #5F6165;" >
                  Hello, ${userName}
                </div>
                
                <div id="userProgress" style="display: flex; align-items: center">
                  <div style="height: 29px; width: 49px; overflow: hidden; object-fit: cover;">
                    <img style="height: 100%; width: 100%" src="${hatIcon}" alt="hat-icon">
                  </div>
                  <div style="height: 7px; width: 185px; background: linear-gradient(180deg, #EDDFF3 0%, #E8EAEC 100%); border-radius: 24px;">
                    <div style="background: linear-gradient(90deg, rgba(0, 231, 212, 0.8) 0%, #247CF1 80.44%); width: 70%; height: 100%; border-radius: 4px;"></div>
                  </div>
                </div>
                
                <div id="userEnergy" style="display: flex; align-items: center">
                  <div style="width: 18px; height: 18px; background: #FB896B; box-shadow: 0 0 12px rgba(255, 71, 56, 0.5); border-radius: 4px; overflow: hidden; object-fit: cover;">
                    <img style="width: 100%; height: 100%" src="${energyIcon}" alt="energy-icon">
                  </div>
                 
                  <span style="font-weight: 600; font-size: 13px; line-height: 10px; color: #5F6165; padding: 0 3px 0 10px">1200</span>
                  <span style="font-weight: 600; font-size: 11px; line-height: 10px; color: #5F6165;">+5/hour</span>
                </div>
                
                <div id="notify" style="display: flex; align-items: center">
                  <div style="width: 25px; height: 25px; overflow: hidden; object-fit: cover;">
                    <img style="width: 100%; height: 100%" src="${bellIcon}" alt="bell-icon">
                  </div>
                  <span style="font-weight: 600; font-size: 13px; line-height: 10px; color: #5F6165; padding-left: 6px">2</span>
                </div>
                
                <div id="gameMode" style="display: flex; align-items: center">
                 <span style="font-weight: 500; font-size: 14px; line-height: 22px; letter-spacing: -0.408px; color: #72767C; padding-right: 10px">Game Mode OFF</span>
                 <div style="display: flex; align-items: center; width: 51px; height: 31px; background: linear-gradient(180deg, #ABA7D9 0%, #E1E8F1 95.16%); box-shadow: inset 0 -0.5px 0 #FFFFFF; border-radius: 20px;">
                 <div style="width: 27px; height: 27px; background: linear-gradient(146.22deg, #C7C5E2 9.7%, #F8FBFF 86.02%); box-shadow: 1px 2px 8px rgba(142, 155, 174, 0.2); border-radius: 50%"></div>
                 </div>
                </div>
            </div>
          </div>
      `
}

export const headerModel = {
    model: {
        defaults: {
            // script,
            attributes: { id: 'header', class: 'row' },
            components: headerComponents('full name'),
            styles: `
                .row {
                    display: table;
                    padding-top: 10px;
                    padding-right: 10px;
                    padding-bottom: 10px;
                    padding-left: 10px;
                    width: 100%;
                    height: '87px';
                    position: 'absolute';
                    top: 0;
                    left: 0;
                    'background': '#FAFAFE';
                    'border-top-left-radius': '20px';
                    'border-top-right-radius': '20px';
                    overflow: 'hidden'
                }
                .cell {
                    width: 8%;
                    display: table-cell;
                    height: 75px;
                }
            
            `,
        }
    }
}


