import SidebarItemTitle from './SidebarItemTitle';
import SidebarIcon from './SidebarIcon';
import SidebarMainMenuContainer from './SidebarMainMenuContainer';
import SidebarItem from './SidebarItem';
import ItemContents from './ItemContents';
import SidebarItems from './SidebarItems';
import ItemContent from './ItemContent';

export default (editor, config = {}) => {
    const dc = editor.DomComponents;
    const opts = {
        ...config,
        defaultModel: dc.getType('default').model,
        editor,
    };

    [
        SidebarItems,
        SidebarMainMenuContainer,
        SidebarItem,
        SidebarItemTitle,
        SidebarIcon,
        ItemContents,
        ItemContent
    ].map(c => c(dc, opts));
}