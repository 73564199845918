export const role = 'item-content';

export default (dc, config) => {

    dc.addType(config.typeItemContent, {
        model: {
            defaults: {
                name: 'Item Content',
                draggable: false,
                copyable: false,
                removable: false,
                highlightable: false,
                attributes: { role },
                classes: config.classItemContent,
                traits: [],
                ...config.itemContentProps
            },
        },
    });
}