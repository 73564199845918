import homeIcon from './components-icons/sidebar-icons/home.svg';
import tournamentsIcon from './components-icons/sidebar-icons/Trophy.svg';
import missionsIcon from './components-icons/sidebar-icons/rocket.svg';
import loyalityLevelIcon from './components-icons/sidebar-icons/award.svg';
import instantRewardsIcon from './components-icons/sidebar-icons/moneybag.svg';
import loyalityStoreIcon from './components-icons/sidebar-icons/shop-bag.svg';
import messagesIcon from './components-icons/sidebar-icons/mail.svg';
import historyIcon from './components-icons/sidebar-icons/History.svg';

const sidebarItems= [
    {id: '1', title: 'dashboard', icon: homeIcon},
    {id: '2', title: 'tournaments', icon: tournamentsIcon},
    {id: '3', title: 'Missions', icon: missionsIcon},
    {id: '4', title: 'Loyality Level', icon: loyalityLevelIcon},
    {id: '5', title: 'Instant Rewards', icon: instantRewardsIcon},
    {id: '6', title: 'Loyality Store', icon: loyalityStoreIcon},
    {id: '7', title: 'Messages', icon: messagesIcon},
    {id: '8', title: 'History', icon: historyIcon}
];

const systemSidebarItems= [
    {id: '1', title: 'Settings', icon: ''},
    {id: '2', title: 'Log out', icon: ''},
];

const leftSidebar = () => {

    const sidebarItemStyles = `
        display: flex;
    `
    const itemTitleStyles = `
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-transform: capitalize;
        color: #ABA7D9;
        padding: 3px 0 0 16px;
    `
    const iconItemStyles = `
        width: 30px;
        object-fit: cover:
    `

    const systemItemTitleStyles = `
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #5F6165;
    `

    const systemIconItemStyles = `
        width: 15px;
        object-fit: cover:
    `

    const items = sidebarItems.map((item) => {
        return `<div style="${sidebarItemStyles}" key="${item.id}">
                  <div style="${iconItemStyles}"><img style="width: 100%" src="${item.icon}" alt="icon"></div> 
                  <span style="${itemTitleStyles}">${item.title}</span>
                </div>`
    })

    const systemItems = systemSidebarItems.map((item) => {
        return `<div style="${sidebarItemStyles}" key="${item.id}">
                  <div style="${systemIconItemStyles}"><img style="width: 100%" src="#" alt="icon"></div> 
                  <span style="${systemItemTitleStyles}">${item.title}</span>
                </div>`
    })

    const mainBlockStyles = `
        width: 280px;
        height: 100%;
        display: flex; 
        flex-direction: column; 
        justify-content: space-around; 
        padding-left: 57px
    `

    const sidebarCellStyles = `
        min-height: 75px;
        flex-grow: 1;
        width: 280px;
        height: 100%;
        max-height: calc(100vh - 87px);
    `
    const bodyCellStyles = `
        min-height: 75px;
        flex-grow: 1;
        flex-basis: 100%;
        width: calc(100% - 280px);
        height: 100%;
        background: #FAFAFE;
    `

    return `
     <div class="cell" data-gjs-type="default" style="${sidebarCellStyles}" data-highlightable="1" id="sidebar-cell">
       <div style="${mainBlockStyles}" >
         <div>${items}</div>
         <div>${systemItems}</div>
       </div>
     </div>
     
     <div class="cell" data-gjs-type="default" style="${bodyCellStyles}" data-highlightable="1" id="body-cell"></div>
    `
}


export const leftSidebarModel = {
    model: {
        defaults: {
            attributes: { id: 'left-sidebar', class: 'row', 'data-highlightable': 1, 'data-gjs-type': "default" },
            components: leftSidebar(),
            style:  {
                display: 'flex',
                'justify-content': 'flex-start',
                'align-items': 'stretch',
                'flex-wrap': 'nowrap',
                'min-height': '75px',
                'flex-grow': '1',
                'flex-basis': '100%',
                height: 'calc(100% - 87px)',
            },
        }
    }
}
