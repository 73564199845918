export const role = 'sidebar-main-menu';

export default (dc, {typeSidebarItems, ...config}) => {
    const type = config.typeSidebarMainMenuContainer;

    dc.addType(type, {
        model: {
            defaults: {
                name: 'Sidebar Main menu',
                copyable: false,
                removable: false,
                highlightable: false,
                attributes: { role },
                components: [{type: typeSidebarItems}],
                classes: config.classSidebarMainMenu,
                ...config.sidebarMainMenuProps
            },
        },
    });
}