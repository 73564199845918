import homeIcon from "../WebBuilderComponents/components-icons/sidebar-icons/home.svg";
import tournamentsIcon from "../WebBuilderComponents/components-icons/sidebar-icons/Trophy.svg";
import missionsIcon from "../WebBuilderComponents/components-icons/sidebar-icons/rocket.svg";
import loyalityLevelIcon from "../WebBuilderComponents/components-icons/sidebar-icons/award.svg";
import instantRewardsIcon from "../WebBuilderComponents/components-icons/sidebar-icons/moneybag.svg";
import loyalityStoreIcon from "../WebBuilderComponents/components-icons/sidebar-icons/shop-bag.svg";
import messagesIcon from "../WebBuilderComponents/components-icons/sidebar-icons/mail.svg";
import historyIcon from "../WebBuilderComponents/components-icons/sidebar-icons/History.svg";

export default {
    // Object to extend the default tabs block, eg. `{ label: 'Tabs', attributes: { ... } }`
    // Pass a falsy value to avoid adding the block
    // tabsBlock: {},
    sidebarItems: [
        {title: 'dashboard', icon: homeIcon},
        {title: 'tournaments', icon: tournamentsIcon},
        {title: 'Missions', icon: missionsIcon},
        {title: 'Loyality Level', icon: loyalityLevelIcon},
        {title: 'Instant Rewards', icon: instantRewardsIcon},
        {title: 'Loyality Store', icon: loyalityStoreIcon},
        {title: 'Messages', icon: messagesIcon},
        {title: 'History', icon: historyIcon}
    ],

    sidebarWithBodyBlock: {},

    typeSidebarItem: 'sidebar-item',
    sidebarItemBlock: {},
    classSidebarItem: 'sidebar-item',
    classSidebarItemActive: 'sidebar-item-active',
    sidebarItemProps: {},

    typeSidebarItems: 'sidebar-items',
    classSidebarItems: 'sidebar-items',
    sidebarItemsProps: {},

    typeItemContents: 'item-contents',
    classItemContents: 'item-contents',
    itemContentsProps: {},

    typeItemContent: 'item-content',
    classItemContent: 'item-content',
    itemContentProps: {},

    typeSidebarMainMenuContainer: 'sidebar-main-menu',
    sidebarMainMenuBlock: {},
    classSidebarMainMenu: 'sidebar-main-menu',
    sidebarMainMenuProps: {},

    typeSidebarItemTitle: 'sidebar-item-title',
    sidebarItemTitleBlock: {},
    classSidebarItemTitle: 'sidebar-item-title',
    sidebarItemTitleProps: {},

    typeSidebarItemIcon: 'sidebar-item-icon',
    sidebarItemIconBlock: {},
    classSidebarIconBlock: 'sidebar-icon-block',
    classSidebarActiveIconBlock: 'sidebar-active-icon-block',
    classSidebarActiveIconInnerBlock: 'sidebar-active-icon-inner-block',
    classSidebarItemIconWrapper: 'sidebar-item-icon-wrapper',
    classSidebarItemIconImg: 'sidebar-item-icon-img',
    sidebarItemIconProps: {},

    // selectorItem: 'aria-controls',
    selectorItem: 'aria-selected',

    // Default template for title content
    templateTitle: (text) => `<div class="sidebar-item-title">${text}</div>`,

    // Default template for icon content
    templateIcon: (icon) => `
                               <div class="sidebar-active-icon-block">
                                 <div class="sidebar-active-icon-inner-block"></div>
                               </div>
                               <div class="sidebar-item-icon-wrapper">
                                 <img class="sidebar-item-icon-img" src="${icon}" alt="icon" >
                               </div> 
                             `,

    templateItemContent: item => '<div>Item Content</div>',

    // Default style for tabs
    style: (config) => `
        .${config.classSidebarItems} {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: nowrap;
            min-height: 75px;
            flex-grow: 1;
            flex-basis: 100%;
            height: calc(100% - 87px);
        }
        .${config.classSidebarMainMenu} {
            min-width: 280px;
            height: 100%;
            display: flex; 
            flex-direction: column;
            justify-content: space-around;
            padding: 24px 0 0 57px; 
        }
        .${config.classSettingsMenu} {
            width: 100%;
            height: 100%;
            display: flex; 
            flex-direction: column; 
        }
        .${config.classSidebarItem} {
            display: flex;
        }
        .${config.classSidebarItemActive}
            position: relative;
            height: 84px;
        }
        .${config.classSidebarIconBlock} {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }
        .${config.classSidebarActiveIconBlock} {
            display: none;
            justify-content: center;
            align-items: center; 
            width: 84px; 
            height: 84px; 
            border-radius: 50%; 
            background: linear-gradient(146.22deg, rgba(219, 224, 231, 0.2) 9.7%, rgba(248, 251, 255, 0.2) 86.02%);
            position: absolute; 
            top: 50%; 
            left: 50%; 
            transform: translate(-50%, -50%); 
        }
        .${config.classSidebarActiveIconInnerBlock} {
            width: 59px; 
            height: 59px; 
            border-radius: 50%; 
            display: flex; 
            justify-content: center; 
            align-items: center; 
            background: linear-gradient(146.22deg, #DBE0E7 9.7%, #F8FBFF 86.02%);
        }
        .${config.classSidebarItemIconWrapper} {
            width: 30px;
            max-height: 60px;
            overflow: hidden;
            object-fit: cover;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .${config.classSidebarItemIconImg} {
            width: 100%;
            height: 100%
            fill: #ABA7D9;
        }
        .${config.classSidebarItemTitle} {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            color: #ABA7D9;
            padding: 3px 0 44px 16px;
            width: 100%;
        }
        .${config.classSidebarItemActive} > .${config.classSidebarItemTitle} {
            color: #6F64D0;
        }
        .${config.classSidebarItemActive} > .${config.classSidebarItemIconImg} svg {
            fill: red;
        }
        .${config.classSidebarItemActive} > .${config.classSidebarActiveIconBlock} {
            display: flex;
        }
        .${config.classItemContent} {
            animation: fadeEffect 1s;
            height: 100%;
            width: 100%;
            background: #FAFAFE;
        }
        .${config.classItemContents} {
            height: 100%;
            flex-grow: 1;
            flex-basis: 100%;
            width: calc(100% - 280px);
            height: 100%;
            background: #FAFAFE;
        }
        @keyframes fadeEffect {
            from {opacity: 0;}
            to {opacity: 1;}
        }
    `
};