export const role = 'sidebar-item-title';

export default (dc, config) => {
    const type = config.typeSidebarItemTitle;

    dc.addType(type, {
        model: {
            defaults: {
                name: 'Sidebar Item',
                copyable: false,
                removable: false,
                highlightable: false,
                attributes: { role },
                components: config.templateTitle,
                classes: config.classSidebarItemTitle,
                ...config.sidebarItemTitleProps
            },
        },
    });
}