import graphIcon from './components-icons/activity-icons/graph.svg';
import timeIcon from './components-icons/activity-icons/time.svg';

const ActivityComponent = () => {
    const fontBasicStyles = `
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 500;
        color: #FFFFFF;
    `

    return `
        <div style="display: flex; justify-content: space-between">
          <span style="${fontBasicStyles} font-size: 20px; line-height: 26px;">Activity</span>
          <span style="${fontBasicStyles} font-size: 14px; line-height: 18px;">View All</span>
        </div>
        <div>
          <img src="${graphIcon}" alt="">
        </div>
        <div>
          <img src="${timeIcon}" alt="">
        </div>
    `

}

export const activityModel = {
    model: {
        defaults: {
            attributes: { id: 'activity' },
            style: {
                display: 'flex',
                'flex-direction': 'column',
                'max-width': '639px',
                'max-height': '270px',
                padding: '27px 28px 17px',
                background: '#3B385A',
                'box-shadow': '0 4px 6px rgba(0, 0, 0, 0.25)',
                'border-radius': '10px',
                overflow: 'hidden'
            },
            components: ActivityComponent()
        }
    }
}