import rangeIcon from './components-icons/rangeCard-icons/range-icon.svg';
import rangeChartIcon from './components-icons/rangeCard-icons/range-chart-icon.svg';

const rangeCardComponents = () => {
    const cardTitleStyles = `
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #242731;
        padding-top: 5px;
    `
    const imgStyles = `
        width: 100%; 
        height: 100%;
    `

    return `
        <div style="display: flex; flex-direction: column; align-items: center">
          <div style="width: 68px; height: 68px; object-fit: cover;">
            <img style="${imgStyles}" src="${rangeIcon}" alt="">
          </div>
          <span style="${cardTitleStyles}">Range</span>
        </div>

        <div style="width: 112px; height: 112px; object-fit: cover; overflow: hidden; padding-top: 15px">
          <img style="${imgStyles}" src="${rangeChartIcon}" alt="">
        </div>
    `
}

export const rangeCardModel = {
    model: {
        defaults: {
            attributes: { id: 'range-card' },
            style: {
                display: 'flex',
                'flex-direction': 'column',
                'justify-content': 'space-between',
                'align-items': 'center',
                padding: '8px 25px',
                width: '198px',
                height: '228px',
                background: '#FFFFFF',
                'box-shadow': '0px 7px 21px rgba(179, 179, 179, 0.25)',
                'border-radius': '14px',
            },
            components: rangeCardComponents()
        }
    }
}