import millIcon from './components-icons/instantRewardsChances-icons/mill.svg';
import diamondIcon from './components-icons/instantRewardsChances-icons/diamond.svg';
import boxIcon from './components-icons/instantRewardsChances-icons/box.svg';

const instantRewardsItems = [
    {title: 'Wheel of fortune', price: '$67', date: '15-Dec-2022', icon: millIcon},
    {title: 'Casino', price: '$67', date: '15-Dec-2022', icon: diamondIcon}
]

const InstantRewardsChancesComponent = () => {
    const iconWrapperStyles = `
        width: 68px;
        height: 68px;
        background: #5D53C2;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        overflow: hidden
    `
    const instantInfoBlockStyles = `
        display: flex;
        flex-direction: column;
        padding-left: 15px;
    `
    const itemTitleStyles = `
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #455A64;
    `
    const itemPriceStyles = `
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #5D53C2;
    `
    const itemDateStyles = `
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #ADA5BD;
    `

    const instantItems = instantRewardsItems.map(item => `
        <div style="display: flex">
          <div style="${iconWrapperStyles}">
            <img style="width: 100%; height: 100%" src="${item.icon}" alt="">
          </div>
          <div style="${instantInfoBlockStyles}">
            <div style="${itemTitleStyles}">${item.title}</div>
            <div style="${itemPriceStyles}">${item.price}</div>
            <div style="${itemDateStyles}">${item.date}</div>
          </div>
        </div>
    `)

    return `
        <div style="display: flex;">
          <div style="display: flex; flex-direction: column; width: 60%; padding: 23px 50px 50px 25px">
            <div style="font-family: 'Poppins'; font-style: normal; font-weight: 500; font-size: 18px; line-height: 27px; color: #3E548D; padding-bottom: 35px">
              Instant Rewards Chances
            </div>
            ${instantItems}
          </div>
          <div style="height: 100%; border: 2px dashed #F4F2F5; position: relative">
            <div style="width: 57px; height: 57px; border-radius: 50%; background: #D9D9D9; position: absolute; top: -28px; left: -28px; z-index: 5"></div>
            <div style="width: 57px; height: 57px; border-radius: 50%; background: #D9D9D9; position: absolute; bottom: -28px; left: -28px; z-index: 5"></div>
          </div>
          <div style="width: 39%; display: flex; justify-content: center; background: #FFFFFF; position: relative">
            <img style="width: 100%; height: 100%; padding-left: 18px" src="${boxIcon}" alt="">
            <div style="width: 48px; height: 48px; border-radius: 50%; background: #D9D9D9; position: absolute; right: 3.89%; top: 3.7%;"></div>
            <div style="width: 35px; height: 35px; border-radius: 50%; background: #D9D9D9; position: absolute; right: calc(3.89% + 35px); top: calc(3.7% + 35px);"></div>
          </div>
        </div>
    `
}

export const InstantRewardsChancesModel = {
    model: {
        defaults: {
            attributes: { id: 'instant-rewards' },
            style: {
                display: 'flex',
                'max-width': '643px',
                'max-height': '312',
                background: '#FFFFFF',
                'box-shadow': '0 4px 6px rgba(0, 0, 0, 0.25)',
                'border-radius': '10px',
                overflow: 'hidden'
            },
            components: InstantRewardsChancesComponent()
        }
    }
}