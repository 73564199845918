export default (dc, {
    typeSidebarItem,
    typeItemContent,
    typeItemContents,
    typeSidebarMainMenuContainer,
    typeSidebarItemIcon,
    typeSidebarItemTitle,
    style,
    ...config
}) => {
    const type = config.typeSidebarItems;

    const script = function(props) {
        const el = this;
        const classItemActive = props.classactive;
        const selectorItem = props.selectoritem;
        const { history, _isEditor } = window;
        const attrItemIndex =  'itemIndex';
        const attrSelected =  'ariaSelected';
        const roleItem = '[role=menu-item]';
        const roleItemContent = '[role=item-content]';
        const { body, location } = document;
        const matches = body.matchesSelector || body.webkitMatchesSelector
            || body.mozMatchesSelector || body.msMatchesSelector;
        const each = (items, clb) => {
            const arr = items || [];
            for (let i = 0; i < arr.length; i++) clb(arr[i], i)
        }
        const hideContents = () => {
            each(el.querySelectorAll(roleItemContent), i => i.hidden = true);
        }
        const getItemId = item => item.id;
        const qS = (elem, qs) => elem.querySelector(qs);
        const getAllItems = () => el.querySelectorAll(roleItem);
        const upItemIdx = (item, val) => !_isEditor && (item[attrItemIndex] = val);
        const activeItem = (itemEl) => {
            each(getAllItems(), (item) => {
                item.className = item.className.replace(classItemActive, '').trim();
                item[attrSelected] = 'false';
                upItemIdx(item, '-1');
            });
            hideContents();
            itemEl.className += ' ' + classItemActive;
            itemEl[attrSelected] = 'true';
            upItemIdx(itemEl, '0');
            const itemContentId = getItemId(itemEl);
            const itemContent = el.querySelectorAll(`[aria-labelledby^="${itemContentId}"]`)[0];
            itemContent && (itemContent.hidden = false);
        };

        const getItemByHash = () => {
            const hashId = (location.hash || '').replace('#', '');
            const qrStr = att => `${roleItem}[${att}=${hashId}]`;
            return hashId && qS(el, qrStr(selectorItem));
        };

        const getSelectedItem = (target) => {
            let found;
            each(getAllItems(), (item) => {
                if (found) return;
                if (item.contains(target)) found = item;
            });
            return found;
        };

        let itemToActive = qS(el, `.${classItemActive}${roleItem}`);
        itemToActive = itemToActive || getItemByHash() || qS(el, roleItem);
        itemToActive && activeItem(itemToActive);

        el.addEventListener('click', (ev) => {
            let { target } = ev;
            let found = matches.call(target, roleItem);

            if (!found) {
                target = getSelectedItem(target);
                if (target) found = 1;
            }

            if (found && !ev.__trg && target.className.indexOf(classItemActive) < 0) {
                ev.preventDefault();
                ev.__trg = 1;
                activeItem(target);
                // const id = getItemId(target);
                // try {
                //     history && history.pushState(null, null, `#${id}`);
                // } catch (e) {}
            }
        });
    };

    const defItems = config.sidebarItems.map(i => (
        {
            type: typeSidebarItem,
            components: [
                { type: typeSidebarItemIcon, components: config.templateIcon(i.icon) },
                { type: typeSidebarItemTitle, components: i.title },
                style && `<style>${style(config)}</style>`
            ]
        })
    );

    const traits = [
        {
            full: 1,
            type: 'button',
            label: false,
            text: 'Add Menu Item',
            command: ed => {
                const sel = ed.getSelected();
                sel && sel.addItem();
            },
        }
    ];

    dc.addType(type, {
        model: {
            defaults: {
                name: 'Sidebar Items',
                classactive: config.classSidebarItemActive,
                selectoritem: config.selectorItem,
                'script-props': ['classactive', 'selectoritem'],
                script,
                traits,
                components: [
                    { type: typeSidebarMainMenuContainer, components: defItems },
                    { type: typeItemContents },
                    style && `<style>${style(config)}</style>`
                ],
                classes: config.classSidebarItems,
                ...config.sidebarItemsProps
            },

            init() {
                this.findItems().map(this.__onItem);
                this.listenTo(this.getItemContainerType().components(), 'add', this.__onItem);
            },

            __onItem(item, v, opts = {}) {
                console.log('__onItem', item)
                !opts.avoidStore && !opts.temporary && item.__initItem && item.__initItem();
            },

            getItemContainerType() {
                return this.findType(typeSidebarMainMenuContainer)[0];
            },

            getContentsType() {
                return this.findType(typeItemContents)[0] || this;
            },

            findItems() {
                return this.findType(typeSidebarItem);
            },

            findContents() {
                return this.findType(typeItemContent);
            },

            addItem(content) {
                const cnt = this.getItemContainerType();
                cnt.append({
                    type: typeSidebarItem,
                    components: content,
                });
            },
        },
    });
}