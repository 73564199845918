import cycleRoundIcon from './components-icons/tournament-icons/cycle-round.svg';
import cycleRectangularIcon from './components-icons/tournament-icons/cycle-rectangular.svg';
import plusIcon from './components-icons/tournament-icons/plus.svg';
import energyIcon from './components-icons/tournament-icons/energy.svg';
import crownIcon from './components-icons/tournament-icons/crown.svg';
import medalIcon from './components-icons/tournament-icons/medal.svg';
import cupIcon from './components-icons/tournament-icons/cup.svg';

const tournamentComponent = () => {
    const mainBlockStyles = `
        display: flex; 
        flex-direction: column; 
        width: 100%; 
        height: 100%
    `

    const basicFontStyles = `
        font-family: 'Montserrat'; 
        font-style: normal; 
        font-weight: 700;
    `

    return `
        <div style="${mainBlockStyles}">
          <div style="display: flex; align-items: center">
          <img style="padding-right: 10px" src="${cycleRoundIcon}" alt="icon">
          <span style="${basicFontStyles}; font-size: 16px; line-height: 20px; color: #242731;">64% Completed</span>
          </div>
          <div style="display: flex; align-items: center; justify-content: center">
            <img src="${crownIcon}" alt="icon">
          </div>
          <div style="${basicFontStyles} font-size: 20px; line-height: 24px; color: #5F6165;">King Tournament</div>
          <div style="display: flex; justify-content: space-between; padding-top: 10px">
            <div style="display: flex; justify-content: space-between">
              <div style="padding-right: 15px"><img src="${cycleRectangularIcon}" alt=""></div>
              <div style="padding-right: 15px; font-family: 'DM Sans'; font-style: normal; font-weight: 500; font-size: 14px; line-height: 18px; color: #ABA7D9;">132K</div>
              <div style="padding-right: 15px"><img src="${plusIcon}" alt=""></div>
              <div style="padding-right: 15px"><img src="${energyIcon}" alt=""></div>
            </div>
            <div style="font-family: 'DM Sans'; font-style: normal; font-weight: 500; font-size: 14px; line-height: 18px; color: #ABA7D9;">$32/h</div>
          </div>
        </div>
    `
}

export const tournamentModel = {
    model: {
        defaults: {
            attributes: { id: 'tournament' },
            style: {
                display: 'flex',
                'flex-direction': 'column',
                'justify-content': 'center',
                'align-items': 'center',
                padding: '15px 27px',
                width: '303px',
                height: '226px',
                background: '#FFFFFF',
                'box-shadow': '0px 7px 21px rgba(179, 179, 179, 0.25)',
                'border-radius': '14px',
            },
            components: tournamentComponent()
        }
    }
}