<template>
  <div id="gjs"></div>
</template>

<script>
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs/dist/grapes.min.js';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { mapActions, mapGetters } from 'vuex';

import { headerModel } from './WebBuilderComponents/HeaderComponent';
import { leftSidebarModel } from './WebBuilderComponents/SidebarComponent';
import { InstantRewardsChancesModel } from './WebBuilderComponents/InstantRewardsChancesComponent';
import { tournamentModel } from './WebBuilderComponents/TournamentComponent';
import { totalInfoModel } from './WebBuilderComponents/TotalInfoComponent';
import { rangeCardModel } from './WebBuilderComponents/RangeCardComponent';
import { activityModel } from './WebBuilderComponents/ActivityComponent';
import sidebarWithBody from './SidebarWithBody/index'

export default {
  name: 'WebBuilder',
  props: {
    changeHtmlData: Function,
    changeCssData: Function,
  },
  data() {
    return {
      model: 'fileObject',
      path: '',
      editor: null,
      css: null,
      html: null,
      parentFolderPath: '',
      repositoryId: '',
      htmlFileName: '',
      htmlFileString: '',
      cssFileString: '',
      query: null,
      canvasWidth: null,
      fileRepositoryName: '',
      pathToCssFile: '',
      fileUri: ''
    }
  },
  mounted() {
    this.editor = grapesjs.init({
      container: '#gjs',
      height: '100%',
      fromElement: true,
      plugins: ['gjs-preset-webpage', sidebarWithBody],
      storageManager: false,
    });

    let undoButton = this.editor.Panels.getButton('options', 'undo');
    undoButton.set({attributes: {title: 'Undo'}});

    let redoButton = this.editor.Panels.getButton('options', 'redo');
    redoButton.set({attributes: {title: 'Redo'}});

    let clearButton = this.editor.Panels.getButton('options', 'canvas-clear');
    clearButton.set({attributes: {title: 'Clear'}});

    let importButton = this.editor.Panels.getButton('options', 'gjs-open-import-webpage');
    importButton.set({attributes: {title: 'Import'}});

    const swVisibilityBtn = this.editor.Panels.getButton('options', 'sw-visibility');
    swVisibilityBtn.set('active', 1);

    this.editor.Panels.addButton('options', [
      {
        id: 'save',
        className: 'fa fa-floppy-o icon-blank',
        command: this.save,
        attributes: { title: 'Save Template' }
      },
    ]);

    this.editor.Components.addType('header', headerModel);
    this.editor.Components.addType('left-sidebar', leftSidebarModel);
    this.editor.Components.addType('tournament', tournamentModel);
    this.editor.Components.addType('instant-rewards', InstantRewardsChancesModel);
    this.editor.Components.addType('total-info', totalInfoModel);
    this.editor.Components.addType('range-card', rangeCardModel);
    this.editor.Components.addType('activity', activityModel);

    this.editor.Blocks.add('header', {
      label: 'Header',
      attributes: { class: 'fa fa-text' },
      content: { type: 'header' },
    });

    this.editor.Blocks.add('left-sidebar', {
      label: 'Body with Left Sidebar',
      attributes: { class: 'fa fa-text' },
      content: { type: 'left-sidebar' },
    });

    this.editor.Blocks.add('tournament', {
      label: 'Tournament',
      attributes: { class: 'fa fa-text' },
      content: { type: 'tournament' },
    });

    this.editor.Blocks.add('instant-rewards', {
      label: 'Instant rewards',
      attributes: { class: 'fa fa-text' },
      content: { type: 'instant-rewards' },
    });

    this.editor.Blocks.add('total-info', {
      label: 'Total info',
      attributes: { class: 'fa fa-text' },
      content: { type: 'total-info' },
    });

    this.editor.Blocks.add('range-card', {
      label: 'Range Card',
      attributes: { class: 'fa fa-text' },
      content: { type: 'range-card' },
    });

    this.editor.Blocks.add('activity', {
      label: 'Activity',
      attributes: { class: 'fa fa-text' },
      content: { type: 'activity' },
    });

    this.editor.Panels.removeButton('views', 'open-tm');

    this.editor.on('load', this.loadData);
    this.editor.on('change', this.changeHtml);
    this.editor.on('change', this.changeCss);
    this.editor.on('change', this.observeCanvasWidth);
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    ...mapGetters('files', ['fileObject']),
    ...mapGetters('fileRepositories', ['fileRepository']),
  },
  watch: {
    canvasWidth: {
      deep: true,
      handler: function(val) {
        if (val) {
          this.editor.refresh();
        }
      }
    },
    fileObject: {
      deep: true,
      handler: async function(val) {
        let folderName = this.fileObject.parentFolderPath.replace('/', '');
        const idx = folderName.indexOf('/');

        if (idx !== -1) {
          const arr = folderName.split('/');
          folderName = arr[arr.length - 1];
        }

        let folderId = '';

        if (folderName) {
          const folder = await this.handleGetFileObjectsByQuery({
            idArray: [],
            queryRequest: {
              must: [
                {
                  queryField: 'repositoryId',
                  queryValues: [this.$router.currentRoute.params.id],
                },
                {
                  queryField: 'mimeType',
                  queryValues: ['inode/directory'],
                },
                {
                  queryField: 'name',
                  queryValues: [folderName],
                },
              ],
            }
          });

          folderId = folder[0].id;
        }

        await this.handleGetFileRepositories({idArray: [this.repositoryId]}).then(data => this.fileRepositoryName = data[0].name);

        const innerFolders = this.$router.currentRoute.query.innerFolders.length
          ? [...JSON.parse(this.$router.currentRoute.query.innerFolders).reverse(), { id: folderId, name: folderName }]
          : [];

        routerBreadcrumbs(this.$router.currentRoute, {
          fileId: val.id,
          fileName: val.name,
          innerFolders: innerFolders,
          repositoryName: this.fileRepository.name,
          id: this.$router.currentRoute.params.id,
        });
      },
    },
  },
  methods: {
    ...mapActions('fileRepositories', ['handleGetFileRepositories']),
    ...mapActions('files', [
      'handleDownloadFileObjects',
      'handleGetFileObjects_item',
      'handleUploadFileObjects',
      'handleGetFileObjectsByQuery'
    ]),
    observeCanvasWidth() {
      const resizeObserver = new ResizeObserver((entries) => {
        this.canvasWidth = entries[0].contentRect.width;
      });

      resizeObserver.observe(document.getElementsByClassName('gjs-cv-canvas')[0]);
    },
    changeHtml(val) {
      this.$emit('change', this.editor.getHtml());

      if (val) {
        this.htmlFileString = this.editor.getHtml();
      }
    },
    changeCss(val) {
      this.$emit('change', this.editor.getCss());

      if (val) {
        this.cssFileString = this.editor.getCss();
      }
    },
    async loadData() {
      await this.handleGetFileObjects_item({idArray: [this.$route.params.fileId]}).then(data => {
        const { path, repositoryId, parentFolderPath, name, uri } = data[0];

        const re = /^(?:https?:\/\/)?(?:www\.)?([^/]+)/;
        this.fileUri = uri.match(re)[0];

        this.path = path;
        this.repositoryId = repositoryId;
        this.parentFolderPath = parentFolderPath;
        this.htmlFileName = name;
      });

      this.query = {
        idArray: [],
        queryRequest: {
          must: [
            {
              queryField: 'repositoryId',
              queryValues: [this.$route.params.id],
            },
            {
              queryField: 'mimeType',
              queryValues: [
                'image/jpeg',
                'image/bmp',
                'image/giff',
                'image/pjpeg',
                'image/png',
                'image/svg+xml',
                'image/webp',
                'image/jpg'
              ]
            }
          ],
          sortBy: [
            {
              queryField: 'isDirectory',
              order: 'Desc'
            },
            {
              queryField: 'created',
              order: 'Desc'
            }
          ],
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage
        }
      };

      const files = await this.handleGetFileObjectsByQuery(this.query);
      files.map(file => this.editor.AssetManager.add({ src: file.uri }))

      const htmlFile = await this.handleDownloadFileObjects({path: this.path});
      this.html = await this.readFileAsText(htmlFile)

      this.editor.setComponents(this.html);

      // css file load
      const pathToCssFile = this.path
        .split('/')
        .filter(item => item !== this.htmlFileName)
        .join('/') + '/style.css';

      this.pathToCssFile = pathToCssFile;

      const cssFile = await this.handleDownloadFileObjects({path: pathToCssFile});
      this.css = await this.readFileAsText(cssFile)

      this.editor.setStyle(this.css)
    },
    async save() {
      const cssHref = `${this.fileUri}${this.pathToCssFile}`
      const html = `
      <html>
        <head>
          <link rel="stylesheet" href=${cssHref} />
        </head>
        <body>${this.htmlFileString}</body>
      </html>
      `

      const htmlFile = this.convertToFile(html, 'text/html', this.htmlFileName);
      const cssFile = this.convertToFile(this.cssFileString, 'text/css', 'style.css');

      await this.handleUploadFileObjects(htmlFile)
      await this.handleUploadFileObjects(cssFile)
    },
    convertToFile(fileString, mimeType, filename) {
      const parts = [
        new Blob([fileString], {
          type: mimeType
        })
      ];

      const file = new File(parts, filename, {
        lastModified: new Date(),
        type: mimeType
      });

      return {
        filesArray: file,
        parentFolderPath: this.parentFolderPath,
        repositoryId: this.repositoryId,
        tagsArray: 'files',
      }
    },
    async readFileAsText(file) {
      return await new Response(file).text();
    },
  },
}
</script>
<style lang="scss">

::-webkit-scrollbar {
  background-color: inherit;
  width: 0;
  height: 0;
}

#gjs {
  .gjs-pn-active {
    color: #007dfb !important;
  }

  #gjs-clm-add-tag {
    color: #1d1d1b;
  }

  .gjs-pn-btn.gjs-pn-active {
    background: rgba(40,47,55,0.31);
    border-radius: 4px;
    box-shadow: 0 0 5px #9ecaed;
  }

  .gjs-radio-item, input:checked+.gjs-radio-item-label {
    background-color: #9ecaed;
    border-radius: 3px;
  }

  .gjs-fonts, .gjs-block{
    &:hover::before {
      color: #007dfb !important;
    }
    &:hover {
      .gjs-block-label {
        color: #007dfb !important;
      }
    }
  }

  .gjs-sm-sector,
  .gjs-sm-stack,
  .sp-choose,
  .gjs-radio-item-label,
  #gjs-sm-input-holder,
  .gjs-sm-layers,
  .gjs-select {
    color: #1d1d1b;
  }

  .gjs-sm-layer {
    background: #282f37;
  }

  .gjs-pn-panel,
  .gjs-blocks-c,
  .gjs-title,
  .gjs-fonts,
  .gjs-block,
  .gjs-clm-tags,
  .gjs-sm-properties,
  .gjs-sm-title {
    background: #282f37;
    color: whitesmoke;
  }

  .gjs-sm-label,
  .gjs-sm-icon,
  #gjs-sm-add {
    color: whitesmoke;
  }

  .gjs-field,
  #gjs-clm-states,  {
    background: #282f37;
    color: whitesmoke;
  }

  .gjs-clm-header-label,
  .gjs-clm-sels,
  .gjs-sm-icon,
  .gjs-sm-sector,
  .gjs-clm-label-sel {
    font-weight: normal;
  }

  .gjs-radio-item,
  .gjs-input-holder,
  .gjs-field,
  .gjs-field-integer {
    background: white;
  }

  .fa-align-left::before,
  .fa-align-center::before,
  .fa-align-right::before,
  .fa-align-justify::before {
    color: #1d1d1b;
  }

  .gjs-radio-item-label {
    margin-bottom: 0;
  }

  .gjs-mdl-header {
    background: #55575f;

    .gjs-mdl-title {
      color: white;
      font-weight: normal;
    }

    .gjs-mdl-btn-close {
      color: white;
      font-weight: normal;
    }
  }

  .gjs-mdl-content {
      background: #ddd;
  }

  .gjs-am-assets-cont, .gjs-am-file-uploader {
    background-color: #ddd;
  }

  #gjs-am-uploadFile, .gjs-am-file-uploader {
    display: none;
  }

  .gjs-mdl-dialog {
    width: 500px;
  }

  .gjs-mdl-content {
    display: flex;

    .gjs-asset-manager {
      display: flex;

      .gjs-am-assets-cont {
        width: 100%;
      }
    }
  }
}
</style>